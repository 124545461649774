import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiCode, FiLayout, FiGithub, FiExternalLink } from 'react-icons/fi';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const highlightedSkills = [
    { name: 'React', icon: <FiCode /> },
    { name: 'TypeScript', icon: <FiCode /> },
    { name: 'TailwindCSS', icon: <FiLayout /> }
  ];

  const featuredProjects = [
    {
      title: 'Escape City Game',
      description: t('escapeCityGameDescription'),
      image: 'https://pellenilsen.no/assets/escapecitygame.jpg',
      link: 'https://www.escapecitygame.no/',
      technologies: ['ReactJS', 'TailwindCSS', 'NodeJS', 'Express', 'MySQL'],
    },
    {
      title: 'Cosmic Explorer',
      description: t('cosmicExplorerDescription'),
      image: 'https://pellenilsen.no/assets/cosmicexplorer.png',
      link: 'https://cosmic-exporer.pellenilsen.no/',
      github: 'https://github.com/PelleNIlsen/Cosmic-Explorer',
      technologies: ['ReactJS', 'TailwindCSS', 'Phaser'],
    },
  ];

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="min-h-screen"
    >
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0 bg-primary">
          <div className="absolute inset-0 opacity-30">
            <div className="absolute top-0 -left-4 w-72 h-72 bg-accent-blue rounded-full mix-blend-multiply filter blur-xl animate-blob" />
            <div className="absolute top-0 -right-4 w-72 h-72 bg-accent-green rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000" />
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-accent-blue rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000" />
          </div>
        </div>

        {/* Content */}
        <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
          <motion.div variants={itemVariants} className="mb-6">
            <motion.h1 
              className="text-4xl md:text-6xl font-bold mb-4 gradient-text"
              variants={textVariants}
            >
              {t('greeting')}
            </motion.h1>
            <motion.p 
              className="text-xl md:text-2xl text-gray-300"
              variants={textVariants}
            >
              {t('role')}
            </motion.p>
          </motion.div>

          {/* Featured Skills */}
          <motion.div 
            variants={itemVariants}
            className="flex flex-wrap justify-center gap-4 my-8"
          >
            {highlightedSkills.map((skill) => (
              <motion.div
                key={skill.name}
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                className="bg-primary-dark/50 backdrop-blur-sm px-6 py-3 rounded-full flex items-center gap-2"
              >
                {skill.icon}
                <span>{skill.name}</span>
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="flex flex-col md:flex-row items-center justify-center gap-4 mt-8"
          >
            <Link 
              to="/projects" 
              className="btn-primary group flex items-center gap-2 bg-accent-blue hover:bg-accent-blue/90 text-primary font-semibold"
            >
              {t('viewProject')}
              <FiArrowRight className="group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link to="/contact" className="btn-secondary">
              {t('contactMe')}
            </Link>
          </motion.div>
        </div>

        {/* Scroll Indicator */}
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: [0, 10, 0] }}
          transition={{ duration: 1.5, repeat: Infinity }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        >
          <div className="w-6 h-10 border-2 border-accent-blue rounded-full p-1">
            <div className="w-2 h-2 bg-accent-blue rounded-full mx-auto animate-bounce" />
          </div>
        </motion.div>
      </section>

      {/* Featured Projects Section */}
      <section className="py-20 px-4 bg-primary-dark/50">
        <div className="container mx-auto max-w-6xl">
          <motion.h2 
            variants={itemVariants}
            className="text-3xl md:text-4xl font-bold mb-12 text-center gradient-text"
          >
            {t('featuredProjects')}
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {featuredProjects.map((project, index) => (
              <motion.div
                key={project.title}
                variants={itemVariants}
                className="bg-primary-dark rounded-lg overflow-hidden group hover:transform hover:scale-[1.02] transition-all duration-300"
              >
                <div className="relative aspect-video overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-primary-dark to-transparent" />
                </div>
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold">{project.title}</h3>
                    <div className="flex gap-3">
                      {project.github && (
                        <a
                          href={project.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-400 hover:text-accent-blue transition-colors"
                        >
                          <FiGithub size={20} />
                        </a>
                      )}
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-400 hover:text-accent-blue transition-colors"
                      >
                        <FiExternalLink size={20} />
                      </a>
                    </div>
                  </div>
                  <p className="text-gray-400 mb-4">{project.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {project.technologies.map((tech) => (
                      <span
                        key={tech}
                        className="px-3 py-1 text-sm bg-accent-blue/10 text-accent-blue rounded-full"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            variants={itemVariants}
            className="text-center mt-12"
          >
            <Link 
              to="/projects"
              className="inline-flex items-center gap-2 text-accent-blue hover:text-accent-blue/80 transition-colors"
            >
              {t('viewAllProjects')}
              <FiArrowRight className="group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Quick Contact Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto max-w-4xl text-center">
          <motion.h2 
            variants={itemVariants}
            className="text-3xl md:text-4xl font-bold mb-6 gradient-text"
          >
            {t('getInTouch')}
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-gray-400 mb-8 max-w-2xl mx-auto"
          >
            {t('contactDescription')}
          </motion.p>
          <motion.div
            variants={itemVariants}
          >
            <Link 
              to="/contact"
              className="btn-primary bg-accent-blue hover:bg-accent-blue/90 text-primary font-semibold inline-flex items-center gap-2"
            >
              {t('contactMe')}
              <FiArrowRight className="group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </section>
    </motion.div>
  );
};

export default Home;
