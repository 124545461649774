import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FiGithub, FiExternalLink } from 'react-icons/fi';
import type { Project } from '../types';

const Projects: React.FC = () => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  // Sample projects data - replace with your actual projects
  const projects: Project[] = [
    {
      id: '1',
      title: 'Escape City Game',
      description: t('escapeCityGameDescription'),
      technologies: ['ReactJS', 'TailwindCSS', 'NodeJS', 'Express', 'MySQL'],
      imageUrl: 'https://pellenilsen.no/assets/escapecitygame.jpg',
      liveUrl: 'https://www.escapecitygame.no/',
      githubUrl: '',
    },
    {
      id: '2',
      title: 'Cosmic Explorer',
      description: t('cosmicExplorerDescription'),
      technologies: ['ReactJS', 'TailwindCSS', 'Phaser'],
      imageUrl: 'https://pellenilsen.no/assets/cosmicexplorer.png',
      liveUrl: 'https://cosmic-exporer.pellenilsen.no/',
      githubUrl: 'https://github.com/PelleNIlsen/Cosmic-Explorer',
    },
    {
      id: '3',
      title: 'HiLink',
      description: t('hiLinkDescription'),
      technologies: ['ReactJS', 'TailwindCSS', 'Figma'],
      imageUrl: 'https://pellenilsen.no/assets/escapecitygame.jpg',
      liveUrl: 'https://hilink.pellenilsen.no/',
      githubUrl: '',
    },
    {
      id: '4',
      title: 'TrackFlix',
      description: t('trackFlixDescription'),
      technologies: ['ReactJS', 'TailwindCSS'],
      imageUrl: 'https://pellenilsen.no/assets/trackflix.png',
      liveUrl: 'https://budget.pellenilsen.no/',
      githubUrl: '',
    },
    {
      id: '5',
      title: 'Gallery',
      description: t('galleryDescription'),
      technologies: ['ReactJS', 'TailwindCSS', 'Figma'],
      imageUrl: 'https://pellenilsen.no/assets/gallery.png',
      liveUrl: 'https://fun.pellenilsen.no/portfolio/',
      githubUrl: '',
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="min-h-screen py-20 px-4"
    >
      <div className="container mx-auto max-w-6xl">
        <motion.h1 
          variants={itemVariants}
          className="text-4xl font-bold mb-12 text-center gradient-text"
        >
          {t('projects')}
        </motion.h1>

        {/* Projects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <motion.div
              key={project.id}
              variants={itemVariants}
              className="bg-primary-dark rounded-lg overflow-hidden group hover:transform hover:scale-[1.02] transition-all duration-300"
              onClick={() => setSelectedProject(project)}
            >
              <div className="relative aspect-video">
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-primary-dark to-transparent" />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-400 mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech) => (
                    <span
                      key={tech}
                      className="px-3 py-1 text-sm bg-accent-blue/10 text-accent-blue rounded-full"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <div className="flex gap-4">
                  {project.githubUrl && (
                    <a
                      href={project.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link"
                      title={t('sourceCode')}
                      aria-label={t('sourceCode')}
                    >
                      <FiGithub size={20} />
                    </a>
                  )}
                  {project.liveUrl && (
                    <a
                      href={project.liveUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link"
                      title={t('liveDemo')}
                      aria-label={t('liveDemo')}
                    >
                      <FiExternalLink size={20} />
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Project Modal */}
      <AnimatePresence>
        {selectedProject && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50"
            onClick={() => setSelectedProject(null)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-primary-dark rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Modal content */}
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-4">{selectedProject.title}</h2>
                <p className="text-gray-400 mb-6">{selectedProject.description}</p>
                <h3 className="text-lg font-semibold mb-3">{t('technologiesUsed')}:</h3>
                <div className="flex flex-wrap gap-2 mb-6">
                  {selectedProject.technologies.map((tech) => (
                    <span
                      key={tech}
                      className="px-3 py-1 text-sm bg-accent-blue/10 text-accent-blue rounded-full"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <div className="flex gap-4">
                  {selectedProject.githubUrl && (
                    <a
                      href={selectedProject.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-primary flex items-center gap-2"
                    >
                      <FiGithub size={20} />
                      {t('sourceCode')}
                    </a>
                  )}
                  {selectedProject.liveUrl && (
                    <a
                      href={selectedProject.liveUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-primary flex items-center gap-2"
                    >
                      <FiExternalLink size={20} />
                      {t('liveDemo')}
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Projects;
