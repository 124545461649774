import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FiCode, FiDatabase, FiLayout, FiServer, FiGitBranch, FiCloud } from 'react-icons/fi';
import type { Skill } from '../types';

const About: React.FC = () => {
  const { t } = useTranslation();

  const skills: Skill[] = [
    {
      name: 'React',
      level: 90,
      icon: 'react',
    },
    {
      name: 'TypeScript',
      level: 85,
      icon: 'typescript',
    },
    {
      name: 'TailwindCSS',
      level: 90,
      icon: 'css',
    },
    {
      name: 'Node.js',
      level: 85,
      icon: 'node',
    },
    {
      name: 'Express',
      level: 80,
      icon: 'backend',
    },
    {
      name: 'MySQL',
      level: 80,
      icon: 'database',
    },
    {
      name: 'Git',
      level: 85,
      icon: 'git',
    },
    {
      name: 'Figma',
      level: 75,
      icon: 'design',
    }
  ];

  const experiences = [
    {
      title: 'Frontend Developer',
      company: 'Escape City Game',
      period: '2023 - Present',
      description: t('escapeCityGameRole')
    },
    {
      title: 'Freelance Developer',
      company: 'Self-employed',
      period: '2022 - Present',
      description: t('freelanceRole')
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const getIconComponent = (iconName: string) => {
    switch (iconName) {
      case 'database':
        return <FiDatabase size={24} className="text-accent-blue" />;
      case 'backend':
        return <FiServer size={24} className="text-accent-blue" />;
      case 'git':
        return <FiGitBranch size={24} className="text-accent-blue" />;
      case 'design':
        return <FiLayout size={24} className="text-accent-blue" />;
      case 'cloud':
        return <FiCloud size={24} className="text-accent-blue" />;
      default:
        return <FiCode size={24} className="text-accent-blue" />;
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="min-h-screen py-20 px-4"
    >
      <div className="container mx-auto max-w-4xl">
        <motion.div variants={itemVariants} className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-6 gradient-text">{t('aboutMe')}</h1>
          <div className="prose prose-invert mx-auto">
            <p className="text-xl text-gray-300">
              {t('aboutDescription')}
            </p>
          </div>
        </motion.div>

        {/* Skills Section */}
        <motion.div variants={itemVariants} className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">{t('skills')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {skills.map((skill) => (
              <motion.div
                key={skill.name}
                variants={itemVariants}
                className="bg-primary-dark p-6 rounded-lg hover:bg-primary-light transition-colors"
              >
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center mr-4">
                    {getIconComponent(skill.icon)}
                  </div>
                  <div className="flex-grow">
                    <h3 className="font-bold">{skill.name}</h3>
                    <div className="w-full bg-gray-700 rounded-full h-2 mt-2">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${skill.level}%` }}
                        transition={{ duration: 1, delay: 0.5 }}
                        className="bg-accent-blue h-2 rounded-full"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Experience Section */}
        <motion.div variants={itemVariants}>
          <h2 className="text-3xl font-bold mb-8 text-center">{t('experience')}</h2>
          <div className="space-y-8">
            {experiences.map((exp, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="bg-primary-dark p-6 rounded-lg hover:bg-primary-light transition-colors"
              >
                <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
                  <div>
                    <h3 className="text-xl font-bold">{exp.title}</h3>
                    <p className="text-accent-blue">{exp.company}</p>
                  </div>
                  <p className="text-gray-400">{exp.period}</p>
                </div>
                <p className="text-gray-300">{exp.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default About;
