import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Navigation
      home: 'Home',
      about: 'About',
      projects: 'Projects',
      resume: 'Resume/CV',
      contact: 'Contact',
      
      // Hero Section
      greeting: "Hi, I'm Pelle Martinius Nilsen",
      role: "Full-Stack Developer specializing in React and Node.js",
      viewAllProjects: 'View All Projects',
      
      // About Section
      aboutMe: 'About Me',
      aboutDescription: 'I am a passionate frontend developer with expertise in creating modern, responsive web applications. My focus is on delivering clean, efficient code and exceptional user experiences.',
      skills: 'Skills',
      skillsDescription: 'Technologies I work with:',
      experience: 'Experience',
      escapeCityGameRole: 'Leading frontend development for Norway\'s first GPS-based escape room, implementing interactive features and responsive design.',
      freelanceRole: 'Working with various clients to deliver custom web solutions, focusing on modern technologies and best practices.',
      
      // Projects Section
      featuredProjects: 'Featured Projects',
      viewProject: 'View Project',
      sourceCode: 'Source Code',
      technologiesUsed: 'Technologies Used',
      liveDemo: 'Live Demo',
      viewDetails: 'View Details',

      // Project Descriptions
      escapeCityGameDescription: 'Norway\'s first GPS-based escape room.',
      cosmicExplorerDescription: 'A web game which won first place in the JSM Coding Challenge 2024.',
      hiLinkDescription: 'A practice project turning a Figma design into a functional web application.',
      trackFlixDescription: 'An application for tracking your subscriptions.',
      galleryDescription: 'A gallery slider where you can view images.',
      
      // Contact Section
      contactMe: 'Contact Me',
      getInTouch: 'Get in Touch',
      contactDescription: 'Have a question or want to work together? Feel free to reach out!',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      location: 'Location',
      place: 'Fredrikstad, Norway',
      timezone: 'Timezone',
      downloadResume: 'Download Resume',
      subject: 'Subject',
      message: 'Message',
      send: 'Send Message',
      gdprConsent: 'I consent to having this website store my submitted information',
      sending: 'Sending message',
      messageSent: 'Message sent successfully',
      messageError: 'An error occurred while sending the message',
      
      // Footer
      rights: 'All rights reserved',
      privacyPolicy: 'Privacy Policy',
      termsOfService: 'Terms of Service',
    },
  },
  no: {
    translation: {
      // Navigation
      home: 'Hjem',
      about: 'Om Meg',
      projects: 'Prosjekter',
      resume: 'CV',
      contact: 'Kontakt',
      
      // Hero Section
      greeting: "Hei, jeg er Pelle Martinius Nilsen",
      role: "Full-Stack Utvikler spesialisert i React og Node.js",
      viewAllProjects: 'Se Alle Prosjekter',
      
      // About Section
      aboutMe: 'Om Meg',
      aboutDescription: 'Jeg er en engasjert frontend-utvikler med ekspertise i å lage moderne, responsive webapplikasjoner. Mitt fokus er på å levere ren, effektiv kode og eksepsjonelle brukeropplevelser.',
      skills: 'Ferdigheter',
      skillsDescription: 'Teknologier jeg jobber med:',
      experience: 'Erfaring',
      escapeCityGameRole: 'Leder frontend-utviklingen for Norges første GPS-baserte escape room, implementerer interaktive funksjoner og responsivt design.',
      freelanceRole: 'Jobber med ulike klienter for å levere tilpassede webløsninger, med fokus på moderne teknologier og beste praksis.',
      
      // Projects Section
      featuredProjects: 'Utvalgte Prosjekter',
      viewProject: 'Se Prosjekt',
      sourceCode: 'Kildekode',
      technologiesUsed: 'Teknologier Brukt',
      liveDemo: 'Live Demo',
      viewDetails: 'Se Detaljer',

      // Project Descriptions
      escapeCityGameDescription: 'Norges første GPS-baserte escape room.',
      cosmicExplorerDescription: 'Et web-spill som vant første plass i JSM Coding Challenge 2024.',
      hiLinkDescription: 'Et prosjekt som gjorde om et Figma-design til en funksjonell webside.',
      trackFlixDescription: 'En applikasjon for kontroll av abonnementer.',
      galleryDescription: 'En portefølje slider hvor du kan se bilder.',
      
      // Contact Section
      contactMe: 'Kontakt Meg',
      getInTouch: 'Ta Kontakt',
      contactDescription: 'Har du et spørsmål eller ønsker å jobbe sammen? Ta gjerne kontakt!',
      name: 'Navn',
      email: 'E-post',
      phone: 'Telefon',
      location: 'Lokasjon',
      place: 'Fredrikstad, Norge',
      timezone: 'Tidssone',
      downloadResume: 'Last ned CV',
      subject: 'Emne',
      message: 'Melding',
      send: 'Send Melding',
      gdprConsent: 'Jeg samtykker til at denne nettsiden lagrer min innsendte informasjon',
      sending: 'Sender melding',
      messageSent: 'Meldingen er sendt',
      messageError: 'En feil oppstod under sendingen av meldingen',
      
      // Footer
      rights: 'Alle rettigheter reservert',
      privacyPolicy: 'Personvernerklæring',
      termsOfService: 'Brukervilkår',
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
