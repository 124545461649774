import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FiGithub, FiInstagram } from 'react-icons/fi';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary-dark mt-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo and Copyright */}
          <div className="text-center md:text-left mb-4 md:mb-0">
            <Link to="/" className="text-xl font-bold gradient-text">
              P.M.N
            </Link>
            <p className="mt-2 text-sm text-gray-400">
              © {currentYear} Pelle Martinius Nilsen. {t('rights')}
            </p>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
            <Link to="/" className="nav-link">
              {t('home')}
            </Link>
            <Link to="/about" className="nav-link">
              {t('about')}
            </Link>
            <Link to="/projects" className="nav-link">
              {t('projects')}
            </Link>
            <Link to="/contact" className="nav-link">
              {t('contact')}
            </Link>
          </div>

          {/* Social Links */}
          <div className="flex items-center space-x-6 mt-4 md:mt-0">
            <a
              href="https://github.com/yourusername"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
              aria-label="GitHub"
            >
              <FiGithub size={24} />
            </a>
            <a
              href="https://instagram.com/yourusername"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
              aria-label="Instagram"
            >
              <FiInstagram size={24} />
            </a>
          </div>
        </div>

        {/* Legal Links */}
        <div className="mt-8 pt-4 border-t border-gray-800 text-center text-sm text-gray-400">
          <div className="flex justify-center space-x-4">
            <Link to="/privacy" className="hover:text-accent-blue transition-colors">
              {t('privacyPolicy')}
            </Link>
            <Link to="/terms" className="hover:text-accent-blue transition-colors">
              {t('termsOfService')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
