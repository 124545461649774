import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FiMail, FiPhone, FiGithub, FiMapPin, FiClock, FiDownload } from 'react-icons/fi';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Implement your form submission logic here
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="min-h-screen py-20 px-4"
    >
      <div className="container mx-auto max-w-4xl">
        <motion.h1 
          variants={itemVariants}
          className="text-4xl font-bold mb-12 text-center gradient-text"
        >
          {t('contact')}
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Information */}
          <motion.div variants={itemVariants} className="space-y-8">
            <h2 className="text-2xl font-bold mb-6">{t('getInTouch')}</h2>
            <p className="text-gray-400 mb-8">{t('contactDescription')}</p>
            
            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center">
                <FiMail size={24} className="text-accent-blue" />
              </div>
              <div>
                <h3 className="font-bold">{t('email')}</h3>
                <a 
                  href="mailto:pellemnilsen@gmail.com" 
                  className="text-gray-400 hover:text-accent-blue"
                  aria-label={`${t('email')}: pellemnilsen@gmail.com`}
                >
                  pellemnilsen@gmail.com
                </a>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center">
                <FiPhone size={24} className="text-accent-blue" />
              </div>
              <div>
                <h3 className="font-bold">{t('phone')}</h3>
                <a 
                  href="tel:+4795048706" 
                  className="text-gray-400 hover:text-accent-blue"
                  aria-label={`${t('phone')}: +47 950 48 706`}
                >
                  +47 950 48 706
                </a>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center">
                <FiMapPin size={24} className="text-accent-blue" />
              </div>
              <div>
                <h3 className="font-bold">{t('location')}</h3>
                <p className="text-gray-400">{t('place')}</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center">
                <FiClock size={24} className="text-accent-blue" />
              </div>
              <div>
                <h3 className="font-bold">{t('timezone')}</h3>
                <p className="text-gray-400">CET (UTC+1)</p>
              </div>
            </div>

            {/* Social Links and Resume */}
            <div className="flex items-center space-x-4 mt-8">
              <a
                href="https://github.com/PelleNIlsen"
                target="_blank"
                rel="noopener noreferrer"
                className="w-12 h-12 bg-accent-blue/10 rounded-lg flex items-center justify-center hover:bg-accent-blue/20"
                aria-label="GitHub"
              >
                <FiGithub size={24} className="text-accent-blue" />
              </a>
              <a
                href="/assets/cv.pdf"
                download
                className="flex-1 btn-primary bg-accent-blue hover:bg-accent-blue/90 text-primary font-semibold flex items-center justify-center gap-2"
                aria-label={t('downloadResume')}
              >
                <FiDownload size={20} />
                {t('downloadResume')}
              </a>
            </div>
          </motion.div>

          {/* Contact Form */}
          <motion.div variants={itemVariants}>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium mb-2">
                  {t('name')}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-primary-dark rounded-lg focus:ring-2 focus:ring-accent-blue"
                  aria-required="true"
                  placeholder={t('name')}
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-2">
                  {t('email')}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-primary-dark rounded-lg focus:ring-2 focus:ring-accent-blue"
                  aria-required="true"
                  placeholder={t('email')}
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-2">
                  {t('message')}
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="w-full px-4 py-2 bg-primary-dark rounded-lg focus:ring-2 focus:ring-accent-blue"
                  aria-required="true"
                  placeholder={t('message')}
                />
              </div>

              <div className="text-sm text-gray-400 mb-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    required
                    className="form-checkbox"
                    aria-required="true"
                  />
                  <span>{t('gdprConsent')}</span>
                </label>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full btn-primary bg-accent-blue hover:bg-accent-blue/90 text-primary font-semibold"
                aria-busy={isSubmitting}
              >
                {isSubmitting ? t('sending') : t('send')}
              </button>

              {submitStatus === 'success' && (
                <p className="text-green-500 text-center" role="status">
                  {t('messageSent')}
                </p>
              )}
              {submitStatus === 'error' && (
                <p className="text-red-500 text-center" role="alert">
                  {t('messageError')}
                </p>
              )}
            </form>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
