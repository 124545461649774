import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'no' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-primary-dark/90 backdrop-blur-sm shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold gradient-text">
            P.M.N
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="nav-link">
              {t('home')}
            </Link>
            <Link to="/about" className="nav-link">
              {t('about')}
            </Link>
            <Link to="/projects" className="nav-link">
              {t('projects')}
            </Link>
            <Link to="/contact" className="nav-link">
              {t('contact')}
            </Link>
            <button
              onClick={toggleLanguage}
              className="px-3 py-1 border border-accent-blue rounded-md hover:bg-accent-blue/10 transition-colors"
              aria-label={t('changeLanguage')}
            >
              {i18n.language === 'en' ? 'NO' : 'EN'}
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsOpen(!isOpen)}
            aria-label={isOpen ? 'Close menu' : 'Open menu'}
          >
            {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden bg-primary-dark/95 backdrop-blur-sm"
            >
              <div className="px-4 py-4 space-y-4">
                <Link to="/" className="block nav-link">
                  {t('home')}
                </Link>
                <Link to="/about" className="block nav-link">
                  {t('about')}
                </Link>
                <Link to="/projects" className="block nav-link">
                  {t('projects')}
                </Link>
                <Link to="/contact" className="block nav-link">
                  {t('contact')}
                </Link>
                <button
                  onClick={toggleLanguage}
                  className="w-full text-left nav-link"
                >
                  {i18n.language === 'en' ? 'Norsk' : 'English'}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
